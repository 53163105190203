<template>
  <div class="loading-spinner" :class="{light:light}">
    <div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinnerVue",
  props: {light:Boolean},
}
</script>

<style lang="scss">
.loading-spinner {
  width: 50px; text-align: center; position: relative; margin:auto;
  .bounce1 { -webkit-animation-delay: -0.32s; -moz-animation-delay: -0.32s; animation-delay: -0.32s; }
  .bounce2 { -webkit-animation-delay: -0.16s; -moz-animation-delay: -0.16s; animation-delay: -0.16s; }
}
.loading-spinner > div { width: 12px; height: 12px; background-color: #fff;  border-radius: 100%; display: inline-block; -webkit-animation: bouncedelay 0.7s infinite ease-in-out; -moz-animation: bouncedelay 0.7s infinite ease-in-out; animation: bouncedelay 0.7s infinite ease-in-out; -webkit-animation-fill-mode: both; -moz-animation-fill-mode: both; animation-fill-mode: both; }
.loading-spinner.loading-spinner-md > div { width: 16px; height: 16px; }
.loading-spinner.loading-spinner-lg{ width: 80px; }
.loading-spinner.loading-spinner-lg > div { width: 24px; height: 24px; }
.loading-spinner.light > div { background-color: #000; }
@keyframes bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.0) }
  40% { -webkit-transform: scale(1.0) }
}
@-webkit-keyframes bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.0) }
  40% { -webkit-transform: scale(1.0) }
}
@-moz-keyframes bouncedelay {
  0%, 80%, 100% { -moz-transform: scale(0.0) }
  40% { -moz-transform: scale(1.0) }
}
</style>
